<template>
  <div
    class="m-confirmation-message justify-content-center align-items-center pr-3 pl-3"
  >
    <div class="message">
      <v-row no-gutters justify="center" class="mb-3">
        <v-icon size="100px" color="teal lighten-2">check_circle</v-icon>
      </v-row>
      <v-container :class="{ 'pa-0': $vuetify.breakpoint.smAndDown }">
        <v-row no-gutters>
          <h4 class="text-center primary--text mb-4 livvic--text">
            Congratulations {{ name }}.
          </h4>
        </v-row>

        <div class="body-1 text-justify">
          You've successfully joined the Mouchak family. Press the button below
          to login directly.
          <!--          <span class="font-weight-bold">-->
          <!--            Check your email to activate your account</span-->
          <!--          >-->
        </div>
      </v-container>
      <v-layout justify-center class="mt-5">
        <v-btn
          @click="directSignin"
          depressed
          :loading="signingIn"
          color="primary"
          >Sign in Directly</v-btn
        >
      </v-layout>
    </div>
  </div>
</template>

<script>
export default {
  name: "MConfirmationMessage",
  props: {
    name: {
      type: String,
      default: "Examinee"
    },
    signingIn: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    directSignin() {
      this.$emit("sign-in");
    }
  }
};
</script>

<style lang="scss"></style>
