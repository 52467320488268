var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "m-confirmation-message justify-content-center align-items-center pr-3 pl-3",
    },
    [
      _c(
        "div",
        { staticClass: "message" },
        [
          _c(
            "v-row",
            {
              staticClass: "mb-3",
              attrs: { "no-gutters": "", justify: "center" },
            },
            [
              _c(
                "v-icon",
                { attrs: { size: "100px", color: "teal lighten-2" } },
                [_vm._v("check_circle")]
              ),
            ],
            1
          ),
          _c(
            "v-container",
            { class: { "pa-0": _vm.$vuetify.breakpoint.smAndDown } },
            [
              _c("v-row", { attrs: { "no-gutters": "" } }, [
                _c(
                  "h4",
                  {
                    staticClass: "text-center primary--text mb-4 livvic--text",
                  },
                  [_vm._v(" Congratulations " + _vm._s(_vm.name) + ". ")]
                ),
              ]),
              _c("div", { staticClass: "body-1 text-justify" }, [
                _vm._v(
                  " You've successfully joined the Mouchak family. Press the button below to login directly. "
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-layout",
            { staticClass: "mt-5", attrs: { "justify-center": "" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    depressed: "",
                    loading: _vm.signingIn,
                    color: "primary",
                  },
                  on: { click: _vm.directSignin },
                },
                [_vm._v("Sign in Directly")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }